
















































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { CrewMemberDocumentUpdateModel } from "@/api/generated";
import momentUtility from "@/utility/momentUtility";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CrewMemberDocumentUpdateDialog extends Validation {
  @Prop(String) private crewMemberDocumentId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: CrewMemberDocumentUpdateModel = {
    reference: "",
    filename: ""
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    try {
      const response = await Api.CrewMemberDocumentService.apiCrewmemberdocumentCrewMemberDocumentIdGet(
        this.crewMemberDocumentId
      );
      this.model = response.data;
      if (this.model.expiryDate) {
        this.model.expiryDate = momentUtility.pickerFormat(
          this.model.expiryDate
        );
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch crew member document");
      this.syncedDialog = false;
    }
  }

  private async onDocumentUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      if (this.model.expiryDate == "") {
        this.model.expiryDate = null;
      }
      await Api.CrewMemberDocumentService.apiCrewmemberdocumentCrewMemberDocumentIdPut(
        this.crewMemberDocumentId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Document updated");
      this.$emit("document-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update document");
    } finally {
      this.syncedDialog = false;
    }
  }
}
