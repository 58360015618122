
















































import {
  Vue,
  Component,
  PropSync,
  Ref,
  Watch,
  Prop
} from "vue-property-decorator";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import CrewMemberDocumentCreateModel from "@/models/crewMemberDocumentCreateModel";
import { apiClient } from "@/services/apiService";
import FormDataFactory from "@/utility/formDataFactory";
import { ContractType } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CrewMemberCertificateCreateDialog extends Vue {
  @Prop(String) private crewMemberId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;
  @Prop({default: ContractType.NUMBER_0}) discriminator!: ContractType;

  private loading = false;
  private model: CrewMemberDocumentCreateModel = {
    crewMemberId: "",
    document: null,
    reference: "",
    filename: "",
    adminViewOnly: false,
    discriminator: this.discriminator
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onCreateDocument() {
    if (!this.form.validate()) {
      return;
    }

    this.model.crewMemberId = this.crewMemberId;
    this.model.filename = this.model.document?.name ?? "";

    try {
      this.loading = true;
      const formData = FormDataFactory.Create(this.model);
      await apiClient.post("api/crewMemberDocument", formData);
      snackbarModule.setSnackbarMessage("Crew member document created");
      this.$emit("document-created");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to create crew member document"
      );
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
