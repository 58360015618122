































































import { DataTableHeader } from "vuetify";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import nameof from "@/utility/nameof";
import Api from "@/api";
import { CrewMemberDocumentModel, ContractType } from '@/api/generated';
import UserModule from "@/store/userModule";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class CrewMemberDocumentTable extends Vue {
  @Prop(String) private crewMemberId!: string;
  @Prop({ default: "Crew Member Documents" }) title?: string;
  @Prop({default: ContractType.NUMBER_0}) discriminator!: ContractType;
  

  private loading = false;
  private showArchived = false;
  private documents: Array<CrewMemberDocumentModel> = [];
  private headers: Array<DataTableHeader<CrewMemberDocumentModel>> = [
    { text: "", value: "actions" },
    { text: "Filename", value: nameof<CrewMemberDocumentModel>("filename") },
    { text: "Reference", value: nameof<CrewMemberDocumentModel>("reference") },
    {
      text: "Document Number",
      value: nameof<CrewMemberDocumentModel>("documentNumber")
    },
    { text: "Uploaded", value: nameof<CrewMemberDocumentModel>("createdUtc") },
    {
      text: "Expiry Date",
      value: nameof<CrewMemberDocumentModel>("expiryDate")
    }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getDocuments();
  }

  @Watch("crewMemberId", { immediate: true })
  private async getDocuments() {
    try {
      this.loading = true;
      if (userModule.role == "AGM") {
        const response = await Api.CrewMemberDocumentService.apiCrewmemberdocumentCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(
          this.crewMemberId,
          this.discriminator
        );
        this.documents = response.data;
      } else {
        const response = await Api.CrewMemberDocumentService.apiCrewmemberdocumentCrewMemberCrewMemberIdDiscriminatorGet(
          this.crewMemberId,
          this.discriminator
        );
        this.documents = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch documents");
    } finally {
      this.loading = false;
    }
  }

  get filteredDocuments(): Array<CrewMemberDocumentModel> {
    if (this.documents.length > 0) {
      return this.documents.filter(x => x.archived == this.showArchived);
    }
    return [];
  }
}
